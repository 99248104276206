
<template lang="pug">
v-container
    v-card(light, flat)
        v-row
            v-spacer
            v-col.text-center
                .text-h4 Advanced Analytics
            v-spacer
        v-row(v-if= '!hasAnalytics')
            v-col.text-center
                v-card
                    v-card-text
                        v-icon(color='brand', size='64') real_estate_agent
                        br
                        span.font-weight-bold Contact Dorsett Controls to explore your options for Advanced Analytics
                        br
                        .text-h6.brand--text Sales
                        span.font-weight-bold Email
                        br
                        a.brand--text(:href='`mailto:${sales.email}`', target='_blank') {{ sales.email }}
                        br
                        br
                        span.font-weight-bold Phone
                        br
                        a.brand--text(:href='`tel:${dorsett.number}`', target='_blank') {{ dorsett.number }}

        v-row(justify='center', v-if='hasAnalytics', )
            v-col.text-center.flex-shrink-1.flex-grow-0
                v-card(width='300', height='200', @click='openSettings=true')
                    v-card-text
                        v-icon(color='brand', size='64') settings
                        br
                        .text-h6.brand--text Settings
                        span Settings
            v-col.text-center.flex-shrink-1.flex-grow-0
                v-card(width='300', height='200', @click='navigateToFlow', :disabled='!canShowTabs')
                    v-card-text
                        v-icon(color='brand', size='64') query_stats
                        br
                        .text-h6.brand--text Flow
                        span Analyze and Predict Flow
                        p(v-if='!canShowTabs' class='missing-settings') Missing settings. Please configure the necessary settings.
            v-col.text-center.flex-shrink-1.flex-grow-0
                v-card(width='300', height='200', :disabled='!canShowTabs')
                    v-card-text
                        v-icon(color='brand', size='64') show_chart
                        br
                        .text-h6.brand--text Power
                        span Power Usage
                        p(v-if='!canShowTabs' class='missing-settings') Missing settings. Please configure the necessary settings.
            v-col.text-center.flex-shrink-1.flex-grow-0
                v-card(width='300', height='200' :disabled='!canShowTabs')
                    v-card-text
                        v-icon(color='brand', size='64') bubble_chart
                        br
                        .text-h6.brand--text Data Analysis
                        span Advanced data analysis tools
                        p(v-if='!canShowTabs' class='missing-settings') Missing settings. Please configure the necessary settings.

    v-dialog(v-model='openSettings', max-width='510px', light)
        v-card(light, class="mx-auto", width='100%')
            v-card-title.brand.white--text.py-2.mb-3  Analytics Settings for {{settingsCustomerName}}
            v-card-text
                v-form(v-model='formValid', ref='settingsForm', lazy-validation)
                    v-row(no-gutters, justify-center)
                        v-col(cols='6', class='field-column').mr-5
                            v-text-field(
                                label='Site Latitude',
                                v-model.number='siteLatitude',
                                outlined,
                                dense,
                                color='brand',
                                type='number',
                                step="0.0001",
                                :rules="[rules.isNumber, rules.latitude]"
                            )
                        v-col(cols='6', class='field-column')
                            v-text-field(
                                label='Site Longitude',
                                v-model.number='siteLongitude',
                                outlined,
                                dense,
                                color='brand',
                                type='number',
                                step="0.0001",
                                :rules="[rules.isNumber, rules.longitude]"
                            )

                    v-row(no-gutters, justify-center)
                        v-col(cols='6', class='field-column').mr-5
                            v-text-field(
                                    label='Storage Max (Millions of Gallons)',
                                    v-model.number='storageMax',
                                    outlined,
                                    dense,
                                    color='brand',
                                    type='number',
                                    :min="0",
                                    :max="100",
                                    :step="2"
                                    :rules="[rules.isNumber, rules.storageMax]"
                                )
                        v-col(cols='6', class='field-column')
                            v-text-field(
                                    label='Storage Min (Millions of Gallons)',
                                    v-model.number='storageMin',
                                    outlined,
                                    dense,
                                    color='brand',
                                    type='number',
                                    :min="0",
                                    :max="100",
                                    :step="2"
                                    :rules="[rules.isNumber, rules.storageMin]"
                                )
                    v-row(no-gutters, justify-center)
                        v-col(cols='6', class='field-column').mr-5
                            v-text-field(
                                label='Production Max (Million GPH)',
                                v-model.number='productionMax',
                                outlined,
                                dense,
                                color='brand',
                                type='number',
                                :min="0",
                                :max="50",
                                :step="1",
                                :rules="[rules.isNumber, rules.productionMax]"
                            )

                        v-col(cols='6', class='field-column')
                            v-text-field(
                                label='Plant Start Time',
                                v-model='plantStartTime',
                                outlined,
                                dense,
                                color='brand',
                                type='text',
                                :rules="[rules.required, rules.plantStartTime]"
                            )
            v-card-actions.d-flex.justify-end.action-style
                v-btn.blue.white--text(@click='submitForm', :disabled="!formValid").mr-2 Save
                v-btn.orange.white--text(@click='closeDialog') Cancel

        div(v-if="error", class="error-message")| An error occurred: {{ error.message }}

</template>

<style  lang="scss" scoped>
.missing-settings {
    color: red;
    font-weight: bold;
    font-size: 0.8rem;
    margin-top: 0.2rem;
    text-align: center;
}

.field-column {
    min-width: 140px;
    max-width: 220px;
}

.action-style {
    padding-top: 8px;
    padding-bottom: 16px;
}

</style>

<script>

export default {
    data () {
        const LIMITS = {
            STORAGE: {
                MAX: 100,
                MIN: 0
            },
            PRODUCTION: {
                MAX: 50,
                MIN: 0
            },
            LONGITUDE: {
                MAX: -67,
                MIN: -180
            },
            LATITUDE: {
                MAX: 50,
                MIN: 25
            }
        };
        return {
            rules: {
                required: (value) => {
                    if(Boolean(value) === true ) {
                        return true;
                    }
                    this.formValid = false;
                    return 'Required';
                },
                isNumber: (value) => {
                    if( !isNaN(parseFloat(value)) ) {
                        return true;
                    }
                    this.formValid = false;
                    return 'Must be a valid number';
                },
                latitude: (value) => {
                    const num = parseFloat(value);
                    if(num >= LIMITS.LATITUDE.MIN && num <= LIMITS.LATITUDE.MAX) {
                        return true;
                    }
                    this.formValid = false;
                    return `Must be between ${LIMITS.LATITUDE.MIN} and ${LIMITS.LATITUDE.MAX}`;
                },
                longitude: (value) => {
                    const num = parseFloat(value);
                    if( num >= LIMITS.LONGITUDE.MIN && num <= LIMITS.LONGITUDE.MAX) {
                        return true;
                    }
                    this.formValid = false;
                    return `Must be between ${LIMITS.LONGITUDE.MIN} and ${LIMITS.LONGITUDE.MAX}`;
                },
                productionMax: (value) => {
                    const num = parseFloat(value);
                    if(num > LIMITS.PRODUCTION.MIN && num <= LIMITS.PRODUCTION.MAX) {
                        return true;
                    }
                    this.formValid = false;
                    return `Must be between ${LIMITS.PRODUCTION.MIN} and ${LIMITS.PRODUCTION.MAX}`;
                },
                storageMax: (value) => {
                    const num = parseFloat(value);
                    if(num > LIMITS.STORAGE.MIN && num <= LIMITS.STORAGE.MAX && num >= this.storageMin) {
                        return true;
                    }
                    if(num > LIMITS.STORAGE.MAX || num < LIMITS.STORAGE.MIN) {
                        return `Must be between ${LIMITS.STORAGE.MIN} and ${LIMITS.STORAGE.MAX}`;
                    }
                    this.formValid = false;
                    return 'Must be greater than or equal to Storage Min';
                },
                storageMin: (value) => {
                    const num = parseFloat(value);
                    if (num >= LIMITS.STORAGE.MIN && num <= LIMITS.STORAGE.MAX && num <= this.storageMax) {
                        return true;
                    }
                    this.formValid = false;
                    if(num > LIMITS.STORAGE.MAX || num < LIMITS.STORAGE.MIN) {
                        return `Must be between ${LIMITS.STORAGE.MIN} and ${LIMITS.STORAGE.MAX}`;
                    }
                    return 'Must be less than or equal to Storage Min';
                },
                plantStartTime: (value) => {
                    // Must be a valid time in 24-hour HH:MM format
                    if(/^([01]?\d|2[0-3]):[0-5]\d$/.test(value)) {
                        return true;
                    }
                    this.formValid = false;
                    return 'Must be a valid time in HH:MM format';
                }
            },
            dorsett: {
                name: 'Dorsett Controls',
                address: '486 N. Patterson Ave. Suite 301',
                city: 'Winston Salem',
                state: 'NC',
                zip: '27101-4264',
                number: '1-855-DT SCADA (387-2232)',
                email: 'info@dorsettcontrols.com',
                fax: '(336) 679-8811'
            },
            training: {
                email: 'training@dorsettcontrols.com'
            },
            support: {
                email: 'support@dorsettcontrols.com'
            },
            sales: {
                email: 'sales@dorsettcontrols.com'
            },
            openSettings: false,
            formValid: false,
            siteLatitude: 0,
            siteLongitude: -90,
            productionMax: 0,
            storageMax: 0,
            plantStartTime: '12:00',
            settingsCustomerName:'',
        };

    },
    computed: {
        canShowTabs () {
            console.log('canShowTabs triggered:');
            const data = this.$store.state.customerData;
            if(!data ) {
                return false;
            }
            if(!data.analyticsConfig) {
                return false;
            }

            // sometimes data does not have analyticsConfig, so we need to check for it
            const { analyticsConfig = {} } = data;
            const { site_latitude, site_longitude, production_max, storage_max, plant_start_time } = analyticsConfig;
            const canShow = site_latitude && site_longitude && production_max && storage_max && plant_start_time?.length > 0;
            console.log('canShowTabs', canShow);
            return canShow;
        },
        hasAnalytics () {
            const data = this.$store.state.customerData;
            if (!data) {
                return false;
            }
            const {customersConfig } = data;
            return Boolean( customersConfig  && customersConfig.hasAnalytics === 1);
        },
        loading () {
            return this.$store.state.loading;
        },
        error () {
            return this.$store.state.error;
        },
    },
    watch: {
        openSettings (val) {
            if (val) {
                this.settingsCustomerName  = this.$store.state.customerData.name + ' ' + this.$store.state.customerData.state;
                const analyticsConfig = this.$store.state.customerData?.analyticsConfig || {};
                this.siteLatitude = analyticsConfig.site_latitude || 0;
                this.siteLongitude = analyticsConfig.site_longitude || 0;
                this.productionMax = analyticsConfig.production_max || 0;
                this.storageMax = analyticsConfig.storage_max || 0;
                this.storageMin = analyticsConfig.storage_min || 0;
                this.plantStartTime = analyticsConfig.plant_start_time || '12:00';
                // plantStartTime needs to be of format HH:mm
                const [hours, minutes] = this.plantStartTime.split(':');
                this.plantStartTime = `${hours}:${minutes.padStart(2, '0')}`;

                // Run validation after the form has been rendered
                this.$nextTick(() => {
                    console.log('Validating form');
                    if (this.$refs.settingsForm) {
                        this.$refs.settingsForm.validate();
                    }
                });
            }
            else if (this.$refs.settingsForm) {
                this.formValid = false;
                this.$refs.settingsForm.resetValidation();
            }
            else {
                console.log('No form to reset');
            }

        },
        '$store.state.customerData.analyticsConfig': {
            handler (newVal) {
                console.log('Watcher triggered:', newVal);
                if (newVal) {
                    this.siteLatitude = newVal.site_latitude !== undefined ? newVal.site_latitude : 0;
                    this.siteLongitude = newVal.site_longitude !== undefined ? newVal.site_longitude : 0;
                    this.productionMax = newVal.production_max !== undefined ? newVal.production_max : 0;
                    this.storageMax = newVal.storage_max !== undefined ? newVal.storage_max : 0;
                    this.storageMin = newVal.storage_min !== undefined ? newVal.storage_min : 0;
                    this.plantStartTime = newVal.plant_start_time !== undefined ? newVal.plant_start_time : '12:00';

                    // Optionally re-validate the form
                    this.$nextTick(() => {
                        if (this.$refs.settingsForm) {
                            this.$refs.settingsForm.validate();
                        }
                    });
                }
            },
            deep: true,
            immediate: true,
        }
    },
    methods: {
        async submitForm () {
            this.$refs.settingsForm.validate();
            if (this.formValid) {
                // Prepare the data to commit to the store
                const updatedConfig = {
                    site_latitude: this.siteLatitude,
                    site_longitude: this.siteLongitude,
                    production_max: this.productionMax,
                    storage_max: this.storageMax,
                    storage_min: this.storageMin,
                    plant_start_time: this.plantStartTime,
                    customerId: this.$store.state.customerData.id
                };
                try{
                    // Dispatch the action (if using actions)
                    await this.$store.dispatch('saveAnalyticsConfig', updatedConfig);

                    this.openSettings = false;
                } catch (error) {
                    console.error('Error saving analytics config', error);
                }
            } else {
                console.log('Form is not valid');
            }
        },
        closeDialog () {
            this.openSettings = false;
        },
        trackChanges (field, value) {
            // Track the fields that have been modified
            this.changedFields[field] = value;
        },
        navigateToFlow () {
            // customer/analytics
            this.$router.push('/customer/analytics/flow')
                .catch(error => {
                    console.error(error);
                });
        }
    }
};

</script>
